<script setup>
import { computed, ref, onMounted } from 'vue';
import { Inertia } from '@inertiajs/inertia';
import { Head } from '@inertiajs/vue3';
import ApplicationMark from '@/Components/ApplicationMark.vue';
import Footer from '@/Components/Footer.vue';
import Banner from '@/Components/Banner.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { CheckIcon } from '@heroicons/vue/20/solid';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import axios from 'axios';

const props = defineProps({
    title: String,
    meta_description: String,
    meta_keywords: String,
});

const showingNavigationDropdown = ref(false);

const switchToTeam = (team) => {
    Inertia.put(route('current-team.update'), {
        team_id: team.id,
    }, {
        preserveState: false,
    });
};

const logout = () => {
    Inertia.post(route('logout'));
};

function getLoginUrl() {
  const domain = (new URL(window.location.href)).hostname.replace('www.','app.');
  return "https://" + domain + "/ci";
}

function goToStock() {

    var str = selectedCompany.value.full_company.split(':');
    if (str.length >= 2) {

      const stock = str[1].split(' ');
      if (stock.length > 0) {
        location.href = '/markets/' + str[0].toLowerCase() + '/' + stock[0];
      }
    }
}

const all = ref([]);

onMounted(() => {
  axios.get('/market-companies')
       .then(response => {
         all.value = response.data;
       })
       .catch(error => {
         console.error('Error fetching market companies:', error);
       });
});

const query = ref('');
const selectedCompany = ref('');
const maxCount = 5;
let count = 0;

const filteredCompanies = computed(() => {
  if (query.value === '') {
    selectedCompany.value = '';
    return [];
  } else {
    return all.value.filter((company, index) => {
      if (index === 0) {
        count = 0;
      }
      if (count < maxCount) {
        let found = company.full_company.toLowerCase().includes(query.value.toLowerCase());
        if (found) {
          count++;
          return true;
        }
      }
      return false;
    });
  }
});

</script>

<template>
    <div>
        <Head :title="title">
            <meta name="description" :content="meta_description">
            <meta name="keywords" :content="meta_keywords">
        </Head>
        <div class="min-h-screen bg-white">
            <nav class="fixed top-0 left-0 right-0 bg-white md:bg-white/75 lg:px-6 z-10 md:backdrop-blur-md h-[112px] lg:h-[64px] border-b border-gray-500/50">
                <!-- Primary Navigation Menu -->
                <div class="mx-auto">
                    <div class="flex justify-between h-16">
                        <div class="flex justify-between w-full flex-col lg:flex-row">
                            <!-- Logo -->
                            <div class="shrink-0 flex items-center justify-center flex-col lg:flex-row">
                                <!-- Link :href="route('dashboard')">
                                    <ApplicationMark class="block h-9 w-auto" />
                                </Link -->
                                <a href="/">
                                    <ApplicationMark class="block h-9 w-auto mt-3 lg:mt-0" />
                                </a>
                                <span class="inline-flex items-stretch gap-2 mt-3 lg:mt-0 ml-6 h-[38px]" v-if="all.length">
                                    <Combobox as="div" v-model="selectedCompany">
                                        <div class="relative w-[260px]">
                                            <ComboboxInput class="block w-[260px] rounded-lg text-sm text-charcoal placeholder:text-gray-900 focus:ring-2 focus:ring-outset focus:ring-primary" @change="query = $event.target.value" :display-value="(company) => company?.full_company" placeholder="Company lookup" />
                                            <ComboboxOptions v-if="filteredCompanies.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ComboboxOption v-for="company in filteredCompanies" :key="company.id" :value="company" as="template" v-slot="{ active, selected }">
                                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-primary hover:text-white hover:cursor-pointer', active ? 'bg-primary text-white' : 'text-gray-900']">
                                                        <span :class="['block truncate', selected && 'font-semibold']">
                                                          {{ company.full_company }}
                                                        </span>
                                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-black' : 'text-indigo-600']">
                                                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                  </li>
                                                </ComboboxOption>
                                            </ComboboxOptions>
                                        </div>
                                      </Combobox>
                                      <button class="px-3 rounded-md bg-primary font-bold text-white hover:bg-charcoal disabled:opacity-25 disabled:bg-gray"  @click="goToStock" :disabled="selectedCompany === ''">Go</button>
                                </span>
                            </div>

                            <!-- Navigation Links -->
                            <div class="hidden space-x-8 lg:-my-px lg:ml-10 lg:flex lg:justify-center">                                
                                <!-- NavLink :href="route('dashboard')" :active="route().current('dashboard')">
                                    Home
                                </NavLink -->
                                <a href="/what-is-cimethod" class="inline-flex items-center px-1 pt-1 text-md leading-5 text-black hover:text-primary focus:text-primary active:text-primary transition whitespace-nowrap">
                                    What is CI Method?
                                </a>
                                <a href="/markets" class="inline-flex items-center px-1 pt-1 text-md leading-5 text-black hover:text-primary focus:text-primary active:text-primary transition whitespace-nowrap">
                                    Markets
                                </a>
                                <a href="/about" class="inline-flex items-center px-1 pt-1 text-md leading-5 text-black hover:text-primary focus:text-primary active:text-primary transition whitespace-nowrap">
                                    About Us
                                </a>
                                <a href="/contact" class="inline-flex items-center px-1 pt-1 text-md leading-5 text-black hover:text-primary focus:text-primary active:text-primary transition whitespace-nowrap">
                                    Contact
                                </a>
                                <a :href="getLoginUrl()" class="inline-flex items-center px-1 pt-1 text-md leading-5 text-black hover:text-primary focus:text-primary active:text-primary transition whitespace-nowrap">
                                  Login
                                </a>

                                <a href="https://quiz.cimethod.com/persona" class="inline-flex items-center rounded-md px-4 mt-3 mb-3 text-md leading-5 bg-primary text-white hover:bg-black focus:bg-black active:bg-black transition whitespace-nowrap">
                                    Apply
                                </a>
                            </div>                                                        
                        </div>

                        <div class="hidden lg:flex lg:items-center lg:ml-6" v-if="$page.props.user">
                            <div class="ml-3 relative">
                                <!-- Teams Dropdown -->
                                <Dropdown v-if="$page.props.jetstream.hasTeamFeatures" align="right" width="60">
                                    <template #trigger>
                                        <span class="inline-flex rounded-md">
                                            <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium whitespace-nowrap rounded-md text-gray-500 bg-white hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:bg-gray-50 active:bg-gray-50 transition">
                                                {{ $page.props.user.current_team.name }}
                                                <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                                </svg>
                                            </button>
                                        </span>
                                    </template>
                                    <template #content>
                                        <div class="w-60">
                                            <!-- Team Management -->
                                            <template v-if="$page.props.jetstream.hasTeamFeatures">
                                                <div class="block px-4 py-2 text-xs text-gray-400">
                                                    Manage Team
                                                </div>
                                                <!-- Team Settings -->
                                                <DropdownLink :href="route('teams.show', $page.props.user.current_team)">
                                                    Team Settings
                                                </DropdownLink>
                                                <DropdownLink v-if="$page.props.jetstream.canCreateTeams" :href="route('teams.create')">
                                                    Create New Team
                                                </DropdownLink>
                                                <div class="border-t border-gray-100" />
                                                <!-- Team Switcher -->
                                                <div class="block px-4 py-2 text-xs text-gray-400">
                                                    Switch Teams
                                                </div>
                                                <template v-for="team in $page.props.user.all_teams" :key="team.id">
                                                    <form @submit.prevent="switchToTeam(team)">
                                                        <DropdownLink as="button">
                                                            <div class="flex items-center">
                                                                <svg v-if="team.id == $page.props.user.current_team_id" class="mr-2 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>

                                                                <div>{{ team.name }}</div>
                                                            </div>
                                                        </DropdownLink>
                                                    </form>
                                                </template>
                                            </template>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>

                            <!-- Settings Dropdown -->
                            <div class="ml-3 relative">
                                <Dropdown align="right" width="48">
                                    <template #trigger>
                                        <button v-if="$page.props.jetstream.managesProfilePhotos" class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
                                            <img class="h-8 w-8 rounded-full object-cover" :src="$page.props.user.profile_photo_url" :alt="$page.props.user.name">
                                        </button>
                                        <span v-else class="inline-flex rounded-md">
                                            <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium whitespace-nowrap rounded-md text-gray-800 bg-charcoal-dark hover:text-white focus:outline-none focus:bg-primary focus:text-white active:bg-charcoal-dark active:text-white transition">
                                                {{ $page.props.user.name }}
                                                <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                </svg>
                                            </button>
                                        </span>
                                    </template>
                                    <template #content>
                                        <!-- Account Management -->
                                        <div class="block px-4 py-2 text-xs text-gray-800">
                                            Manage Account
                                        </div>
                                        <DropdownLink :href="route('profile.show')">
                                            Profile
                                        </DropdownLink>
                                        <DropdownLink v-if="$page.props.jetstream.hasApiFeatures" :href="route('api-tokens.index')">
                                            API Tokens
                                        </DropdownLink>
                                        <div class="border-t border-gray-100" />
                                        <!-- Authentication -->
                                        <form @submit.prevent="logout">
                                            <DropdownLink as="button">
                                                Log Out
                                            </DropdownLink>
                                        </form>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>
                        <!-- Hamburger -->
                        <div class="flex items-center lg:hidden">
                            <button class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-primary focus:outline-none focus:bg-primary focus:text-white transition" @click="showingNavigationDropdown = ! showingNavigationDropdown">
                                <svg
                                    class="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Responsive Navigation Menu -->
                <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="lg:hidden bg-white relative -mt-1">
                    <div class="pt-2 pb-3 space-y-1">
                        <!-- ResponsiveNavLink :href="route('dashboard')" :active="route().current('dashboard')">
                            Dashboard
                        </ResponsiveNavLink -->
                        <a href="/what-is-cimethod" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base text-center font-medium text-black hover:text-primary hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition">
                            What is CI Method?
                        </a>
                        <a href="/markets" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base text-center font-medium text-black hover:text-primary hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition">
                            Markets
                        </a>
                        <a href="/about" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base text-center font-medium text-black hover:text-primary hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition">
                            About Us
                        </a>
                        <a href="/contact" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base text-center font-medium text-black hover:text-primary hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition">
                            Contact
                        </a>
                    </div>
                    <!-- Responsive Settings Options -->
                    <div class="pt-4 pb-1 border-t border-gray-200" v-if="$page.props.user">
                        <div class="flex items-center px-4">
                            <div v-if="$page.props.jetstream.managesProfilePhotos" class="shrink-0 mr-3">
                                <img class="h-10 w-10 rounded-full object-cover" :src="$page.props.user.profile_photo_url" :alt="$page.props.user.name">
                            </div>
                            <div>
                                <div class="font-medium text-base text-gray-800">
                                    {{ $page.props.user.name }}
                                </div>
                                <div class="font-medium text-sm text-gray-500">
                                    {{ $page.props.user.email }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 space-y-1">
                            <ResponsiveNavLink :href="route('profile.show')" :active="route().current('profile.show')">
                                Profile
                            </ResponsiveNavLink>

                            <ResponsiveNavLink v-if="$page.props.jetstream.hasApiFeatures" :href="route('api-tokens.index')" :active="route().current('api-tokens.index')">
                                API Tokens
                            </ResponsiveNavLink>
                            <!-- Authentication -->
                            <form method="POST" @submit.prevent="logout">
                                <ResponsiveNavLink as="button">
                                    Log Out
                                </ResponsiveNavLink>
                            </form>
                            <!-- Team Management -->
                            <template v-if="$page.props.jetstream.hasTeamFeatures">
                                <div class="border-t border-gray-200" />
                                <div class="block px-4 py-2 text-xs text-gray-400">
                                    Manage Team
                                </div>
                                <!-- Team Settings -->
                                <ResponsiveNavLink :href="route('teams.show', $page.props.user.current_team)" :active="route().current('teams.show')">
                                    Team Settings
                                </ResponsiveNavLink>
                                <ResponsiveNavLink v-if="$page.props.jetstream.canCreateTeams" :href="route('teams.create')" :active="route().current('teams.create')">
                                    Create New Team
                                </ResponsiveNavLink>
                                <div class="border-t border-gray-200" />
                                <!-- Team Switcher -->
                                <div class="block px-4 py-2 text-xs text-gray-400">
                                    Switch Teams
                                </div>
                                <template v-for="team in $page.props.user.all_teams" :key="team.id">
                                    <form @submit.prevent="switchToTeam(team)">
                                        <ResponsiveNavLink as="button">
                                            <div class="flex items-center">
                                                <svg v-if="team.id == $page.props.user.current_team_id" class="mr-2 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <div>{{ team.name }}</div>
                                            </div>
                                        </ResponsiveNavLink>
                                    </form>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                <Banner />
            </nav>
            <!-- Page Heading -->
            <header v-if="$slots.header" class="mx-6 pb-6 sm:pb-6 lg:pb-9 pt-6 lg:pt-9 mt-[96px] mt:h-[64px]">
                <div class="mx-auto">
                    <slot name="header" />
                </div>
            </header>
            <!-- Page Content -->
            <main>
               
                <slot />
            </main>
            <Footer />
        </div>
    </div>
</template>

<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import { defineComponent, h } from 'vue'
import ApplicationMark from '@/Components/ApplicationMark.vue';

const form = useForm({
  email: null,
});

const submit = () => {
  form.post(route('contact.store'), {
  });
};

const navigation = {
  about: [
    { name: 'Home', href: '/' },
    { name: 'What is CI Method', href: '/what-is-cimethod' },
    { name: 'About Us', href: '/about' },
    { name: 'Contact', href: '/contact' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
            }),
          ]),
      }),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
  ],
}
</script>
<template>
  <footer class="bg-charcoal" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-16">
      <div class="text-center flex flex-col lg:flex-row lg:gap-12 items-start border-b border-charcoal-light mb-8">
        <div class="text-left w-full lg:w-3/5 mb-8">
          <Link :href="route('dashboard')">
            <img loading="lazy" class="inline-block h-9 w-auto" src="@images/logo-cimethod-reversed.svg" alt="" />
          </Link>
          <ul role="list" class="block sm:inline-flex gap-4 mt-2 w-full">
            <li v-for="item in navigation.about" :key="item.name" class="py-2">
              <a :href="item.href" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/icon-arrow.svg" alt="" />
                <span class="block">{{ item.name }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="text-left w-full lg:w-2/5">
          <!-- div class="relative">
            <div>
              <h5 class="text-xs uppercase font-semibold leading-6 text-[color:white]">Join our mailing list</h5>
              <p class="text-xs text-gray-800 hidden">We are currently welcoming like minded investors from around the world to join us as Friends of CI Method. Become a friend and receive regular useful and interesting free content that will help you become a better investor.</p>
            </div>
            <div class="pb-4">
              <form @submit.prevent="submit" class="mt-2 flex items-center bg-charcoal-light border-charcoal-light rounded-md p-1 gap-2 border">
                <label for="email" class="sr-only">Email</label>
                <input v-model="form.email" type="email" name="email" id="email" required class="block w-full rounded-lg bg-charcoal-light border-0 p-0 px-2 text-white placeholder:text-gray-400 focus:ring-0 text-sm" placeholder="you@example.com">
                <button type="submit" class="flex items-center justify-center rounded-md bg-primary px-3 py-1 font-bold text-white hover:bg-charcoal text-sm">Join</button>
              </form>
            </div>
          </div-->
        </div>
      </div>
      <div class="text-center grid grid-cols-2 md:grid-cols-3 gap-y-16 lg:grid-cols-5 lg:gap-6 items-start">
        <div class="text-left justify-start">
          <!-- h3 class="text-xs uppercase font-semibold leading-6 text-white">Markets</h3 -->
          <ul role="list" class="w-full">
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">North America</span>
            </li>
            <li class="py-2">
              <a href="/markets/can" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/CA.svg" alt=""><span class="block">Canada</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/mx" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/MX.svg" alt=""><span class="block">Mexico</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/us" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/US.svg" alt=""><span class="block">United States</span>
              </a>
            </li>
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">South America &amp; Caribbean</span>
            </li>
            <li class="py-2">
              <a href="/markets/ar" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/AR.svg" alt=""><span class="block">Argentina</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/br" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/BR.svg" alt=""><span class="block">Brazil</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/cl" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/CL.svg" alt=""><span class="block">Chile</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/co" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/CO.svg" alt=""><span class="block">Colombia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/do" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/DO.svg" alt=""><span class="block">Domincan Republic</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/pe" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/PE.svg" alt=""><span class="block">Peru</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="text-left justify-start">
          <ul role="list" class="w-full">
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">Central &amp; Western Europe</span>
            </li>
            <li class="py-2">
              <a href="/markets/at" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/AT.svg" alt=""><span class="block">Austria</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/be" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/BE.svg" alt=""><span class="block">Belgium</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/cz" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/CZ.svg" alt=""><span class="block">Czechia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/fr" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/FR.svg" alt=""><span class="block">France</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/de" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/DE.svg" alt=""><span class="block">Germany</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/hu" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/HU.svg" alt=""><span class="block">Hungary</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ie" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/IE.svg" alt=""><span class="block">Ireland</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/nl" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/NL.svg" alt=""><span class="block">Netherlands</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/pl" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/PL.svg" alt=""><span class="block">Poland</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/to" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/TO.svg" alt=""><span class="block">Switzerland</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/uk" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/UK.svg" alt=""><span class="block">United Kingdom</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="text-left justify-start">
          <ul role="list" class="w-full">
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">Northern &amp; Eastern Europe</span>
            </li>
            <li class="py-2">
              <a href="/markets/dk" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/DK.svg" alt=""><span class="block">Denmark</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/fi" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/FI.svg" alt=""><span class="block">Finland</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/lt" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/LT.svg" alt=""><span class="block">Lithuania</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/no" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/NO.svg" alt=""><span class="block">Norway</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ro" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/RO.svg" alt=""><span class="block">Romania</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ru" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/RU.svg" alt=""><span class="block">Russia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/rs" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/RS.svg" alt=""><span class="block">Serbia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/se" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/SE.svg" alt=""><span class="block">Sweden</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="text-left">
          <ul role="list" class="w-full">
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">Southern Europe</span>
            </li>
            <li class="py-2">
              <a href="/markets/gr" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/GR.svg" alt=""><span class="block">Greece</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/it" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/IT.svg" alt=""><span class="block">Italy</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/pt" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/PT.svg" alt=""><span class="block">Portugal</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/es" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/ES.svg" alt=""><span class="block">Spain</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/tr" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/TR.svg" alt=""><span class="block">Turkey</span>
              </a>
            </li>
          </ul>
          <ul role="list" class="mt-6 w-full">
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">Africa</span>
            </li>
            <li class="py-2">
              <a href="/markets/eg" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/EG.svg" alt=""><span class="block">Egypt</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ke" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/KE.svg" alt=""><span class="block">Kenya</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ma" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/MA.svg" alt=""><span class="block">Morocco</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ng" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/NG.svg" alt=""><span class="block">Nigeria</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/za" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/ZA.svg" alt=""><span class="block">South Africa</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="text-left justify-start">
          <ul role="list" class="w-full">
            <li class="py-2">
              <span class="text-xs uppercase font-semibold leading-6 text-gray-900">Asia &amp; Australia</span>
            </li>
            <li class="py-2">
              <a href="/markets/aus" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/AU.svg" alt=""><span class="block">Australia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/cn" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/CN.svg" alt=""><span class="block">China</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/hk" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/HK.svg" alt=""><span class="block">Hong Kong</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/in" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/IN.svg" alt=""><span class="block">India</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/id" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/ID.svg" alt=""><span class="block">Indonesia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/il" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/IL.svg" alt=""><span class="block">Israel</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/jp" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/JP.svg" alt=""><span class="block">Japan</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/my" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/MY.svg" alt=""><span class="block">Malaysia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/nz" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/NZ.svg" alt=""><span class="block">New Zealand</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/pk" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/PK.svg" alt=""><span class="block">Pakistan</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ph" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/PH.svg" alt=""><span class="block">Philippines</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/sa" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/SA.svg" alt=""><span class="block">Saudi Arabia</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/sg" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/SG.svg" alt=""><span class="block">Singapore</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/kr" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/KR.svg" alt=""><span class="block">South Korea</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/tw" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/TW.svg" alt=""><span class="block">Taiwan</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/th" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/TH.svg" alt=""><span class="block">Thailand</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/ae" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/AE.svg" alt=""><span class="block">United Arab Emirates</span>
              </a>
            </li>
            <li class="py-2">
              <a href="/markets/vn" class="text-sm text-gray-800 hover:text-white flex gap-2 justify-start items-center content-center whitespace-nowrap">
                <img loading="lazy" class="w-4 h-4 object-cover rounded-full" src="@images/components/flags/1x1/VN.svg" alt=""><span class="block">Vietnam</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center bg-charcoal-dark py-6">
      <div class="flex items-start justify-between max-w-7xl mx-auto px-8 text-sm">
        <div class="text-left text-white/20 w-full w-1/2">
          Copyright &copy;2023
        </div>
        <div class="text-right text-white/20 w-full w-1/2 flex gap-4 justify-end">
          <a href="/privacy" class="block text-sm text-white/20 hover:text-white whitespace-nowrap">Privacy</a>
          <a href="/terms" class="block text-sm text-white/20 hover:text-white whitespace-nowrap">Terms</a>
        </div>
      </div>
    </div>
  </footer>
</template>